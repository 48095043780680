import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import './ChildrenGroupHeader.css'

export default () => {
  const data = useStaticQuery(graphql`
    query childrenGroupQuery {
      file(relativePath: {eq: "ChildrenGroup_images/ChildrenGroupBackground.jpg"}) {
        childImageSharp {
          fluid (maxWidth: 2000, quality: 100){
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
    `)
  return (
    <div>
      <BackgroundImage fluid={data.file.childImageSharp.fluid} className='ChildrenGroupheader_background'>
        <div className='ChildrenGroupheader_title'>
          <h1>兒 童 事 工</h1>
        </div>
      </BackgroundImage>
    </div>
  )
}