import React from 'react'
import { Col, Row } from 'antd'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"
import './ChildrenGroupContent.css'

export default () =>{
    const data = useStaticQuery ( graphql`
    fragment childrenImage on File {
        childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
        }
    }
    query childimageQuery {
        child1: file(relativePath: { eq: "ChildrenGroup_images/ChildrenActivity1.jpg" }) {
            ...childrenImage
        }
        child2: file(relativePath: { eq: "ChildrenGroup_images/ChildrenActivity2.jpg" }) {
            ...childrenImage
        }
        child3: file(relativePath: { eq: "ChildrenGroup_images/ChildrenActivity3.jpg" }) {
            ...childrenImage
        }
        child4: file(relativePath: { eq: "ChildrenGroup_images/ChildrenActivity4.jpg" }) {
            ...childrenImage
        }
        child5: file(relativePath: { eq: "ChildrenGroup_images/ChildrenActivity5.jpg" }) {
            ...childrenImage
        }
        child6: file(relativePath: { eq: "ChildrenGroup_images/ChildrenActivity6.jpg" }) {
            ...childrenImage
        }
        childbook: file(relativePath: { eq: "ChildrenGroup_images/GG3.jpeg" }) {
            ...childrenImage
        }
    }
    `)
    return(
        <div>
            <div className='desktop'>
            <div className='childrencontent_title'>
                <Row className='cardsRowDesktop1' align='middle'>
                    <Col span={9} offset={4}>
                        <h2>兒童主日學</h2>
                        <p>
                            <strong>主要負責同工</strong>
                                <br />嬰兒班 劉靜姐妹
                                <br />學步兒班  林純純姐妹
                                <br />K-1班 譚德建姐妹
                        </p>
                        <p>
                            <strong>K-5主講教師</strong>: Jessica Lee
                        </p>
                        <p>
                            <strong>K-5敬拜引領</strong>: Anita Tan, Gabby Cui, Jocelyn Hayter and Angelin Lin
                        </p>
                        <p>
                            <strong>主日敬拜及課程</strong>
                                <br />1-6月：線上
                                <br />7-10月：教會戶外為主
                                <br />1-12月：教會室內
                        </p>                    
                    </Col>
                    <Col span={6} offset={1}>
                        <Img fluid={data.childbook.childImageSharp.fluid} />
                    </Col>
                </Row>
            </div>
            <div className='childrenpic_title'>
                <h1>兒童小組活動照片</h1>
            </div>
            <div>
                <Row justify='center' align='middle' gutter={[24,24]} className='childrenDesktopRow1'>
                    <Col span={6}>
                        <Img fluid={data.child1.childImageSharp.fluid} />
                    </Col>
                    <Col span={6}>
                        <Img fluid={data.child2.childImageSharp.fluid} />
                    </Col>
                    <Col span={6}>
                        <Img fluid={data.child3.childImageSharp.fluid} />
                    </Col>
                </Row>
                <Row justify='center' align='middle' gutter={[24,24]} className='childrenDesktopRow1'>
                    <Col span={6}>
                        <Img fluid={data.child4.childImageSharp.fluid} />
                    </Col>
                    <Col span={6}>
                        <Img fluid={data.child5.childImageSharp.fluid} />
                    </Col>
                    <Col span={6}>
                        <Img fluid={data.child6.childImageSharp.fluid} />
                    </Col>
                </Row>
            </div>
            </div>
            <div className='mobile'>
            <Row className='cardsRowMobile1' align='middle'>
                <Col span={20} offset={2}>
                <h2>兒童主日學</h2>
                        <p>
                            <strong>主要負責同工</strong>
                                <br />嬰兒班 劉靜姐妹
                                <br />學步兒班  林純純姐妹
                                <br />K-1班 譚德建姐妹
                        </p>
                        <p>
                            <strong>K-5主講教師</strong>: Jessica Lee
                        </p>
                        <p>
                            <strong>K-5敬拜引領</strong>: Anita Tan, Gabby Cui, Jocelyn Hayter and Angelin Lin
                        </p>
                        <p>
                            <strong>主日敬拜及課程</strong>
                                <br />1-6月：線上
                                <br />7-10月：教會戶外為主
                                <br />1-12月：教會室內
                        </p>      
                </Col>
            </Row>
            <Row className='cardsRowMobile2' align='middle'>
                <Col span={20} offset={2}>
                    <Img fluid={data.childbook.childImageSharp.fluid} />
                </Col>
            </Row>
            <div className='childrenpic_title'>
                <h1>兒童小組活動照片</h1>
            </div>
            <div>
            <Row className='cardsRowMobile3' align='middle'>
                <Col span={20} offset={2}>
                <Img fluid={data.child1.childImageSharp.fluid} />
                </Col>
            </Row>
            <Row className='cardsRowMobile3' align='middle'>
                <Col span={20} offset={2}>
                <Img fluid={data.child2.childImageSharp.fluid} />
                </Col>
            </Row>
            <Row className='cardsRowMobile3' align='middle'>
                <Col span={20} offset={2}>
                <Img fluid={data.child3.childImageSharp.fluid} />
                </Col>
            </Row>
            <Row className='cardsRowMobile3' align='middle'>
                <Col span={20} offset={2}>
                <Img fluid={data.child4.childImageSharp.fluid} />
                </Col>
            </Row>
            <Row className='cardsRowMobile3' align='middle'>
                <Col span={20} offset={2}>
                <Img fluid={data.child5.childImageSharp.fluid} />
                </Col>
            </Row>
            <Row className='cardsRowMobile3' align='middle'>
                <Col span={20} offset={2}>
                <Img fluid={data.child6.childImageSharp.fluid} />
                </Col>
            </Row>
            </div>
            </div>
        </div>
    )
}